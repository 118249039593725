const {
  KANA_PHONEME_TABLE,
  PALATALIZERS,
  NON_KANA,
  PALATALIZER,
  GEMINATE,
} = require('./data');
const { isPunctuation, getPunctuation } = require('./punctuation');

const FOLLOWING_GEMINATE = ['っ', 'ッ'];

function toPhonemes(kana, { nonKanaAllowed }) {
  let phonemes = [];

  for (const char of kana) {
    const segments = KANA_PHONEME_TABLE[char];

    if (segments !== undefined) {
      phonemes.push(...segments);
      continue;
    }

    const palatalization = PALATALIZERS[char];

    if (palatalization !== undefined) {
      phonemes.pop();
      phonemes.push(PALATALIZER);
      phonemes.push(palatalization.vowel_segment);
      continue;
    }

    if (FOLLOWING_GEMINATE.includes(char)) {
      phonemes.push(GEMINATE);
      continue;
    }

    if (isPunctuation(char)) {
      phonemes.push(getPunctuation(char));
      continue;
    }

    if (nonKanaAllowed == true) {
      phonemes.push(NON_KANA(char));
    } else {
      throw new Error(`Error at ${char}`);
    }
  }

  return phonemes;
}

module.exports = {
  toPhonemes,
};
