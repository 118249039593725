const asPunctuation = (kana, romanized) => ({ kana, romanized });

const RAW_PUNCTUATION = {
  '－': '-',
  '。': '.',
  '、': ',',
  '？': '?',
  '！': '!',
  '：': ':',
  '〜': '~',
  '　': ' ',
  '『': '"',
  '』': '"',
  '〝': '"',
  '〟': '"',
  '「': "'",
  '」': "'",
  '・': '-',
  '…': '...',
  '｛': '{',
  '｝': '}',
  '（': '(',
  '）': ')',
  '［': '[',
  '］': ']',
  '【': '"',
  '】': '"',
};

const ENGLISH_PUNCTUATION = Object.values(RAW_PUNCTUATION);

const ALL_PUNCTUATION = Object.keys(RAW_PUNCTUATION).concat(
  ENGLISH_PUNCTUATION,
);

function isPunctuationPhoneme(segment) {
  return segment.kana !== undefined && segment.romanized !== undefined;
}

function isPunctuation(char) {
  return ALL_PUNCTUATION.includes(char);
}

function getPunctuation(char) {
  if (ENGLISH_PUNCTUATION.includes(char)) {
    return asPunctuation(char, char);
  }

  return asPunctuation(char, RAW_PUNCTUATION[char]);
}

module.exports = {
  isPunctuation,
  getPunctuation,
  isPunctuationPhoneme,
};
