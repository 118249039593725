const { isNonKana, PHONEME_ROMAJI_TABLE } = require('./data');
const { isPunctuationPhoneme } = require('./punctuation');
const {
  MORAIC_N,
  VOWELS,
  Y,
  A,
  I,
  U,
  O,
  E,
  S,
  T,
  H,
  PALATALIZER,
  GEMINATE,
} = require('./data');

const VOWEL_MERGES = [
  {
    vowels: [A, A],
    romaji: 'ā',
  },
  {
    vowels: [U, U],
    romaji: 'ū',
  },
  {
    vowels: [I, I],
    romaji: 'ī',
  },
  {
    vowels: [E, E],
    romaji: 'ē',
  },
  {
    vowels: [O, O],
    romaji: 'ō',
  },
  {
    vowels: [O, U],
    romaji: 'ō',
  },
];

function getMerge(first, second) {
  return VOWEL_MERGES.find(({ vowels: [a, b] }) => first === a && second === b);
}

function canBeMerged(first, second) {
  return Boolean(getMerge(first, second));
}

function toRomaji(phonemes, { mergeLongVowels, anglophone }) {
  let romanized = '';

  let i = 0;
  while (i < phonemes.length) {
    const phoneme = phonemes[i];
    const nextPhoneme = phonemes[i + 1];
    const previousPhoneme = phonemes[i - 1];

    const palatalizePrevious = () => {
      if (!anglophone) return;

      if (previousPhoneme === S) {
        romanized = romanized + 'h';
      } else if (previousPhoneme === T) {
        if (phoneme === U) {
          romanized = romanized + 's';
        } else {
          romanized = romanized.slice(1, -1) + 'ch';
        }
      } else if (previousPhoneme === H) {
        romanized = romanized.slice(1, -1) + 'f';
      }
    };

    if (phoneme === PALATALIZER) {
      palatalizePrevious();

      if (!anglophone) {
        romanized = romanized + 'y';
      }
    } else if (phoneme === GEMINATE) {
      const romaji = PHONEME_ROMAJI_TABLE[nextPhoneme];
      romanized = romanized + romaji + romaji;
      // this case requires an extra increment
      i++;
    } else if (isPunctuationPhoneme(phoneme)) {
      romanized = romanized + phoneme.romanized;
    } else if (isNonKana(phoneme)) {
      romanized = romanized + phoneme.value;
    } else if (mergeLongVowels && canBeMerged(phoneme, nextPhoneme)) {
      romanized = romanized + getMerge(phoneme, nextPhoneme).romaji;
      // this case requires an extra increment
      i++;
    } else {
      if (phoneme === I) {
        palatalizePrevious();
      }

      if (phoneme === U && [T, H].includes(previousPhoneme)) {
        palatalizePrevious();
      }

      romanized = romanized + PHONEME_ROMAJI_TABLE[phoneme];
    }

    if (phoneme === MORAIC_N && VOWELS.concat(Y).includes(nextPhoneme)) {
      romanized = romanized + "'";
    }

    i++;
  }

  return romanized;
}

module.exports = {
  toRomaji,
};
