const m = require('mithril')

const Loading = {
  view: function() {
    return m('.loader')
  },
}

module.exports = {
  Loading,
}
