const A = 'A';
const I = 'I';
const U = 'U';
const E = 'E';
const O = 'O';

const K = 'K';
const S = 'S';
const T = 'T';
const N = 'N';
const H = 'H';
const M = 'M';
const R = 'R';
const G = 'G';
const Z = 'Z';
const D = 'D';
const B = 'B';
const P = 'P';
const W = 'W';
const Y = 'Y';

const MORAIC_N = 'MORAIC_N';

const PALATALIZER = 'PALATALIZER';

const GEMINATE = 'GEMINATE';

const VOWELS = [A, I, U, E, O];

const table_row = (consonant, [first, second, third, fourth, fifth]) => ({
  [first]: [consonant, A],
  [second]: [consonant, I],
  [third]: [consonant, U],
  [fourth]: [consonant, E],
  [fifth]: [consonant, O],
});

const HIRAGANA_PHONEME_TABLE = {
  あ: [A],
  い: [I],
  う: [U],
  え: [E],
  お: [O],
  ...table_row(K, 'かきくけこ'),
  ...table_row(S, 'さしすせそ'),
  ...table_row(T, 'たちつてと'),
  ...table_row(N, 'なにぬねの'),
  ...table_row(H, 'はひふへほ'),
  ...table_row(M, 'まみむめも'),
  ...table_row(R, 'らりるれろ'),
  ...table_row(G, 'がぎぐげご'),
  ...table_row(Z, 'ざじずぜぞ'),
  ...table_row(D, 'だぢづでど'),
  ...table_row(B, 'ばびぶべぼ'),
  ...table_row(P, 'ぱぴぷぺぽ'),
  わ: [W, A],
  ゐ: [W, I],
  ゑ: [W, E],
  を: [W, O],
  や: [Y, A],
  ゆ: [Y, U],
  よ: [Y, O],
  ん: [MORAIC_N],
};

const KATAKANA_PHONEME_TABLE = {
  ア: [A],
  イ: [I],
  ウ: [U],
  エ: [E],
  オ: [O],
  ...table_row(K, 'カキクケコ'),
  ...table_row(S, 'サシスセソ'),
  ...table_row(T, 'タチツテト'),
  ...table_row(N, 'ナニヌネノ'),
  ...table_row(H, 'ハヒフヘホ'),
  ...table_row(M, 'マミムメモ'),
  ...table_row(R, 'ラリルレロ'),
  ...table_row(G, 'ガギグゲゴ'),
  ...table_row(Z, 'ザジズゼゾ'),
  ...table_row(D, 'ダヂヅデド'),
  ...table_row(B, 'バビブベボ'),
  ...table_row(P, 'パピプペポ'),
  ワ: [W, A],
  ヰ: [W, I],
  ヱ: [W, E],
  ヲ: [W, O],
  ヤ: [Y, A],
  ユ: [Y, U],
  ヨ: [Y, O],
  ン: [MORAIC_N],
};

const KANA_PHONEME_TABLE = {
  ...HIRAGANA_PHONEME_TABLE,
  ...KATAKANA_PHONEME_TABLE,
};

const PHONEME_ROMAJI_TABLE = {
  [A]: 'a',
  [I]: 'i',
  [U]: 'u',
  [E]: 'e',
  [O]: 'o',
  [K]: 'k',
  [S]: 's',
  [T]: 't',
  [N]: 'n',
  [H]: 'h',
  [M]: 'm',
  [R]: 'r',
  [G]: 'g',
  [Z]: 'z',
  [D]: 'd',
  [B]: 'b',
  [P]: 'p',
  [W]: 'w',
  [Y]: 'y',
  [MORAIC_N]: 'n',
};

const PALATALIZERS = {
  ゃ: { vowel_segment: A },
  ゅ: { vowel_segment: U },
  ょ: { vowel_segment: O },
  ャ: { vowel_segment: A },
  ュ: { vowel_segment: U },
  ョ: { vowel_segment: O },
};

const NON_KANA = char => ({
  type: 'NON_KANA',
  value: char,
});

const isNonKana = any => any.type === 'NON_KANA';

module.exports = {
  KANA_PHONEME_TABLE,
  PALATALIZERS,
  PHONEME_ROMAJI_TABLE,
  NON_KANA,
  isNonKana,
  A,
  I,
  U,
  E,
  O,
  K,
  S,
  T,
  N,
  H,
  M,
  R,
  G,
  Z,
  D,
  B,
  P,
  W,
  Y,
  MORAIC_N,
  VOWELS,
  PALATALIZER,
  GEMINATE,
};
