const SUCCESS = 'SUCCESS';
const ERROR = 'ERROR';

const success = result => ({
  status: SUCCESS,
  result,
});

const error = result => ({ status: ERROR, result });

module.exports = { success, error, SUCCESS, ERROR };
