const { success, error } = require('./helper');
const { toRomaji } = require('./romaji');
const { toPhonemes } = require('./phoneme');

const defaultConfig = {};

function romanize(kana, config = defaultConfig) {
  try {
    return success(toRomaji(toPhonemes(kana, config), config));
  } catch (e) {
    return error(e.message);
  }
}

module.exports = {
  romanize,
};
